<template>
	<div class="content-campaign-throttling">
		<p>
			Limit the number of leads this campaign purchases to allow time to work each lead, or to stretch your daily budget
			further.
		</p>
		<div class="throttling-switch">
			<div class="switch">
				<p-input-switch
					input-id="purchase_throttle_enable"
					v-model="campaignStore.campaign.purchase_throttling.enabled"
				/>
			</div>
			<label for="purchase_throttle_enable" class="clickable"> Enable Purchase Throttling </label>
		</div>
		<div v-if="campaignStore.campaign.purchase_throttling.enabled" class="options">
			<gutter size="10px" />
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-10">
						<div class="field fit">Allow up to</div>
						<div class="field fit xxs">
							<p-input-number
								v-model="campaignStore.campaign.purchase_throttling.limit"
								name="throttling_purchase_input"
								class="input-align-center"
								placeholder="5"
							/>
						</div>
						<div class="field fit">purchases every</div>
						<div class="field fit xxs">
							<p-input-number
								v-model="campaignStore.campaign.purchase_throttling.interval"
								name="throttling_time_input"
								class="input-align-center"
								placeholder="300"
							/>
						</div>
						<div class="field fit">seconds.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { useCampaignStore } from '@/stores/campaign';
import { mapStores } from 'pinia';

export default {
	name: 'ThrottlingContent',
	computed: {
		...mapStores(useCampaignStore),
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.throttling-switch {
	align-items: center;
	display: flex;
	flex-direction: row;
	font-size: var(--font-size-sm);
	font-weight: bold;
	gap: 10px;
	justify-content: flex-start;
	margin: 10px 0;
}
.clickable:hover {
	cursor: pointer;
}
</style>
