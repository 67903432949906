<template>
	<div id="transaction-list">
		<query-form v-model:query="query" :loading="loading" @apply="getTransactions" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Type:</div>
					{{ displaySelected(query.filters.type, transaction_type_options) }}
				</div>
				<div class="display-value">
					<div class="label">Show Purchases:</div>
					{{ show_purchases ? 'Yes' : 'No' }}
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getTransactions(true)"
				/>
				<CSVReport
					v-if="transactions.length > 0"
					:query="query"
					report="transactions"
					:show_purchases="show_purchases"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range
											v-model="form.query.filters.created_at"
											hide-labels
											show-time
											:rangeLimit="{ days: 90 }"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="20px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="control-label">Filter by Transaction Type:</div>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.filters.type"
											option-label="label"
											option-value="value"
											:options="transaction_type_options"
											placeholder="Filter by Type"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<div class="field flex justify-content-start align-items-center gap-2">
									<p-checkbox v-model="show_purchases" name="category" binary />
									<label class="check-label">Show Purchases</label>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="flex gap-2 justify-content-start flex-wrap">
			<AddFundsModal @refresh="getTransactions(true)" />
			<BalanceAdjustmentModal v-if="$root.sessionStore.isAdminUser" @refresh="getTransactions(true)" />
			<SimpleTransferModal />
			<p-dialog :modal="true" v-model:visible="open_pg_transaction_modal" @after-hide="closePGModal">
				<template #header>
					<div class="flex gap-2 align-content-center align-items-center">
						<strong>Payment Gateway Request</strong>
					</div>
				</template>
				<div>
					<h3>Request</h3>
					<highlightjs autodetect :code="pg_transaction.request" />
				</div>
				<gutter size="20px" />
				<div>
					<h3>Response</h3>
					<highlightjs autodetect :code="pg_transaction.response" />
				</div>
				<template #footer>
					<p-button label="Cancel" text @click="closePGModal" />
				</template>
			</p-dialog>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table scrollable :loading="loading" :value="transactions">
				<template #empty>
					<div class="dim">No Transactions found</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column field="created_at" header="Date">
					<template #body="transaction">
						<div>
							{{ formatDate(transaction.data.created_at, 'YYYY-MM-DD &bull; h:mm:ssa') }}
							<div v-if="transaction.data.created_at" class="sub-value">
								{{ timeAgo(transaction.data.created_at) }}
							</div>
						</div>
					</template>
				</p-column>
				<p-column field="account.id" header="Account" v-if="isMPReport">
					<template #body="transaction">
						<div>
							{{ transaction.data.account.name }} <br />
							<span class="sub-value">{{ transaction.data.account.id }}</span>
						</div>
					</template>
				</p-column>
				<p-column field="type" header="Type">
					<template #body="transaction">
						<div class="type">
							{{ transaction.data.sub_type === 'AUTO FUNDING' ? 'AUTO-FUND' : transaction.data.type }}
						</div>
					</template>
				</p-column>
				<p-column field="payment_method.last_four" header="Additional Details">
					<template #body="transaction">
						<div
							v-if="transaction.data.payment_method_id && $root.appStore.isAdminApp"
							class="type click-target"
							@click="openPGModal(transaction)"
						>
							{{ transaction.data.payment_method?.name_on_card }} ({{ transaction.data.payment_method?.last_four }})
							<br />
							<span class="sub-value"
								>TXN ID:{{ transaction.data.pg_txn_id }} <icon type="open-in-new" size="20px" />
							</span>
						</div>
						<div v-else-if="transaction.data.payment_method_id" class="type">
							{{ transaction.data.payment_method?.name_on_card }} ({{ transaction.data.payment_method?.last_four }})
							<br />
							<span class="sub-value">TXN ID:{{ transaction.data.pg_txn_id }} </span>
						</div>
						<div
							v-else-if="transaction.data.sub_type"
							v-tooltip.top="transaction.data.sub_type.length > 30 ? transaction.data.sub_type : null"
						>
							{{ transaction.data.sub_type.slice(0, 30) }} {{ transaction.data.sub_type.length > 30 ? '...' : '' }}
						</div>
					</template>
				</p-column>
				<p-column field="amount" header="Amount" class="align-center">
					<template #body="transaction">
						{{ currency(transaction.data.amount) }}
					</template>
				</p-column>
				<p-column field="start_balance" header="Start Balance" class="align-center">
					<template #body="transaction">
						{{ currency(transaction.data.start_balance) }}
					</template>
				</p-column>
				<p-column field="end_balance" header="End Balance" class="align-center">
					<template #body="transaction">
						{{ currency(transaction.data.end_balance) }}
					</template>
				</p-column>
				<p-column field="created_by" header="Created By" class="align-center">
					<template #body="transaction">
						{{
							transaction.data.created_by.trim().indexOf('SYSTEM') > -1
								? 'SYSTEM'
								: `${transaction.data.created_by_user.first_name} ${transaction.data.created_by_user.last_name}`
						}}
					</template>
				</p-column>
				<p-column header="Tools" class="align-center">
					<template #body="transaction">
						<div class="toolset">
							<Receipt
								v-if="transaction.data.sub_type !== 'Purchase' && transaction.data.payment_method_id"
								:transaction="transaction.data"
							/>
							<Refund
								v-if="
									$root.sessionStore.isAdminUser &&
									transaction.data.type === 'deposit' &&
									transaction.data.sub_type !== 'refunded'
								"
								@refresh="getTransactions(true)"
								:transaction="transaction.data"
							/>
						</div>
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="total_row_count" @page="handlePageChange" />
		</div>
	</div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { formatDate, displaySelected, currency, verticalName, phoneFormat, timeAgo } from '@/lib/Filters';
import pPaginator from 'primevue/paginator';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import AddFundsModal from '@/views/AddFunds/Modal.vue';
import Receipt from '../Tools/Receipt.vue';
import Refund from '../Tools/Refund.vue';
import { getTransactionsList } from '@GQL';
import { transaction_type_options } from '@/lib/Options';
import BalanceAdjustmentModal from '@/views/Transactions/Record/BalanceAdjustmentModal.vue';
import Gutter from '@/components/elements/Gutter.vue';
import CSVReport from '@/components/widgets/CSVReport.vue';
import SimpleTransferModal from '../Record/SimpleTransferModal.vue';

export default {
	name: 'TransactionList',
	components: {
		CSVReport,
		Gutter,
		dateRange,
		queryForm,
		AddFundsModal,
		Receipt,
		Refund,
		BalanceAdjustmentModal,
		pPaginator,
		SimpleTransferModal,
	},
	data() {
		const start = dayjs().subtract(3, 'month').startOf('day');
		const end = dayjs().endOf('day');
		return {
			loading: false,
			query: {
				filters: {
					created_at: [start, end],
					type: [],
				},
				pagination: {
					page_size: 25,
					page: 0,
				},
				order: {
					field: 'created_at',
					desc: true,
				},
			},
			show_purchases: false,
			open_pg_transaction_modal: false,
			pg_transaction: {
				request: '',
				response: '',
			},
			transaction_type_options,
			total_row_count: 0,
			transactions: [],
		};
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id ||
				this.$route.params.parent_account_id ||
				this.$root.sessionStore.user.account_id ||
				('mpid' in this.$route.params ? `${this.$route.params.mpid}-000-000` : `${this.$root.appStore.mpid}-000-000`)
			);
		},
		mpid() {
			if ('params' in this.$route && this.$route.params.mpid) {
				return this.$route.params.mpid;
			} else {
				return this.$root.appStore.mpid;
			}
		},
		isMPReport() {
			return this.accountId.indexOf('000-000') > -1;
		},
	},
	created() {
		this.getTransactions();
	},
	methods: {
		timeAgo,
		clearQuery() {
			this.query.filters.created_at = [dayjs().startOf('day'), dayjs().endOf('day')];
			this.query.filters.type = [];
			this.show_purchases = false;
			this.query.pagination = {
				page_size: 10,
				page: 0,
			};
		},
		openPGModal(transaction) {
			this.pg_transaction.request = transaction.data.pg_transaction.request;
			this.pg_transaction.response = transaction.data.pg_transaction.response;
			this.open_pg_transaction_modal = true;
		},
		closePGModal() {
			this.pg_transaction.request = '';
			this.pg_transaction.response = '';
			this.open_pg_transaction_modal = false;
		},
		currency,
		formatDate,
		displaySelected,
		verticalName,
		phoneFormat,
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getTransactions();
		},
		async getTransactions(refresh?: boolean) {
			this.loading = true;

			const filters = [
				{
					field: 'created_at',
					op: 'bt',
					value: this.query.filters.created_at,
				},
			];

			// if accountId ends in 000-000 then we should replace the account_id filter with an MPID one
			if (this.isMPReport) {
				filters.push({
					field: 'mpid',
					op: 'eq',
					value: this.mpid, // always required for this page to work
				});
			} else {
				filters.push({
					field: 'account_id',
					op: 'eq',
					value: this.accountId, // always required for this page to work
				});
			}

			if (this.query.filters.type.length > 0) {
				filters.push({
					field: 'type',
					op: 'in',
					value: this.query.filters.type,
				});
			}

			if (!this.show_purchases) {
				filters.push(`(sub_type IS NULL OR NOT sub_type LIKE 'Purchase%')`);
			}

			const query_params = {
				...this.query,
				filters: [filters],
			};

			try {
				const { row_count, rows } = await getTransactionsList(query_params, refresh);
				this.total_row_count = row_count;
				this.transactions = rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get the transactions',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		formatCSVData(data) {
			switch (data.field) {
				case 'created_at':
					return this.formatDate(data.data, 'YYYY-MM-DD h:mm:ssa');
				case 'start_balance':
					return this.currency(data.data);
				case 'amount':
					return this.currency(data.data);
				case 'end_balance':
					return this.currency(data.data);
				default:
					return data.data;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.type {
	display: block;
	line-height: 1.5;
	text-transform: uppercase;
}
.check-label {
	font-size: 1.2em;
	line-height: 1.5;
}
.click-target {
	cursor: pointer;
}
</style>
