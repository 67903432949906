<template>
	<div class="sign-up-page w-full overflow-y-auto">
		<p-dialog
			:modal="true"
			:dismissable-mask="true"
			v-model:visible="show_offer_modal"
			:pt="{
				root: { class: 'w-12 sm:w-12 md:w-6' },
			}"
		>
			<template #header>
				<div class="offer-header">
					<h1>Claim This Offer!</h1>
				</div>
			</template>
			<div class="special-offer content flex flex-column align-items-center">
				<div class="tag-line">
					<div v-if="signup_page.content.body">
						<div v-html="signup_page.content.body" />
					</div>
					<div v-else-if="signup_page.offer.credit_type === 'flat'">
						<strong>Exclusive Offer: </strong>
						<span v-if="signup_page.offer.trigger === 'deposit'">
							Receive {{ currency(signup_page.offer.credit_amount, 0) }} when you fund your account with at least
							{{ currency(signup_page.offer.required_funding, 0) }}
						</span>
						<span v-else>
							Receive {{ currency(signup_page.offer.credit_amount, 0) }} when your <strong>first funding </strong> is at
							least {{ currency(signup_page.offer.required_funding, 0) }}
						</span>
					</div>
					<div v-else-if="signup_page.offer.credit_type === 'percentage'">
						<strong>Exclusive Offer:</strong>
						<span v-if="signup_page.offer.trigger === 'deposit'">
							Receive {{ percentage(signup_page.offer.credit_amount, 0) }} when you fund your account with at least
							{{ currency(signup_page.offer.required_funding, 0) }}
						</span>

						<span v-else>
							Receive {{ percentage(signup_page.offer.credit_amount, 0) }} when your <strong>first funding </strong> is
							at least {{ currency(signup_page.offer.required_funding, 0) }}
						</span>
					</div>
				</div>
				<div class="logo" v-if="signup_page.content.logo_url">
					<img class="logo-large" :src="signup_page.content.logo_url" alt="Company Logo" />
				</div>
				<p-button style="font-size: 1.5rem" label="Let's Get Started!" @click="show_offer_modal = false" />
			</div>
		</p-dialog>
		<loader :show="loading" />
		<div class="header">
			<h1>Get Better Leads!</h1>
			<div class="subtitle">Join the thousands of agents who have joined the NextGen Leads family.</div>
		</div>
		<div class="content-wrapper">
			<gutter size="40px" />
			<row>
				<column class="column">
					<p-card v-if="step === 1">
						<template #content>
							<form id="sign-up-form">
								<h2>Tell us about your business</h2>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">What is your primary vertical?</label>
										<div class="controls">
											<div class="field">
												<div
													v-for="vertical in vertical_options"
													:key="vertical.value"
													class="mt-2 flex align-items-center"
												>
													<p-radio-button
														v-model="account.signup_data.primary_vertical"
														:inputId="vertical.value"
														name="dynamic"
														:value="vertical.value"
													/>
													<label :for="vertical.value" class="ml-2 clickable">{{ vertical.label }}</label>
												</div>
												<div v-if="v$.account.signup_data.primary_vertical.$error" class="validation-error">
													{{ v$.account.signup_data.primary_vertical.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<gutter size="10px" />
								<div class="control-group">
									<div class="inner">
										<label class="control-label">What type of products are you interested in?</label>
										<div class="controls">
											<div class="field">
												<div v-for="product in product_options" class="flex align-items-center gap-2">
													<p-checkbox
														v-model="account.signup_data.products"
														name="category"
														:input-id="product.value"
														:value="product.value"
													/>
													<label :for="product.value" class="clickable">{{ product.label }}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">How many people are you buying leads for?</label>
										<div class="controls">
											<div class="field">
												<div class="mt-2 flex gap-3 flex-wrap flex-column sm:flex-row">
													<div v-for="agents in agent_options" :key="agents.label" class="flex align-items-center">
														<p-radio-button
															v-model="account.signup_data.agency_size"
															:inputId="agents.value"
															name="dynamic"
															:value="agents.value"
														/>
														<label :for="agents.value" class="clickable ml-2">{{ agents.label }}</label>
													</div>
												</div>
												<div v-if="v$.account.signup_data.agency_size.$error" class="validation-error">
													{{ v$.account.signup_data.agency_size.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr class="divider" />
								<h2>Contact Information</h2>
								<div class="control-group">
									<div class="inner">
										<div class="controls gap-20">
											<div class="field">
												<label class="control-label req" for="first-name">First Name:</label>
												<p-input-text
													id="first-name"
													v-model="user.first_name"
													class="p-inputtext"
													placeholder="First"
												/>
												<div v-if="v$.user.first_name.$error" class="validation-error">
													{{ v$.user.first_name.$errors[0].$message }}
												</div>
											</div>
											<div class="field">
												<label class="control-label req" for="last-name">Last Name:</label>
												<p-input-text id="last-name" v-model="user.last_name" class="p-inputtext" placeholder="Last" />
												<div v-if="v$.user.last_name.$error" class="validation-error">
													{{ v$.user.last_name.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<div class="controls gap-20">
											<div class="field">
												<label class="control-label req" for="email">Email:</label>
												<p-input-text
													id="email"
													v-model.trim="user.email"
													class="p-inputtext"
													placeholder="name@example.com"
												/>
												<div v-if="v$.user.email.$error" class="validation-error">
													{{ v$.user.email.$errors[0]?.$message }}
												</div>
											</div>
											<div class="field">
												<label class="control-label req" for="phone">Phone Number:</label>
												<p-input-mask
													id="phone"
													v-model="user.phone"
													mask="(999) 999-9999"
													:unmask="true"
													:auto-clear="false"
													placeholder="(999) 999-9999"
												/>
												<div v-if="v$.user.phone.$error" class="validation-error">
													{{ v$.user.phone.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<div class="controls gap-20">
											<div class="field">
												<label class="control-label req" for="password">Password:</label>
												<p-password
													id="new_password"
													v-model="user.password"
													placeholder="New Password"
													toggle-mask
													strong-regex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-`{-~])(?=.*[0-9])(?=.{8,})"
												>
													<template #header>
														<div class="mb-2 font-bold">Pick a password</div>
													</template>
													<template #footer>
														<p-divider />
														<p class="mt-2">Password requirements</p>
														<ul class="pl-2 ml-2 mt-0" style="font-size: var(--font-size-sm); line-height: 1.5">
															<li>At least one lowercase</li>
															<li>At least one uppercase</li>
															<li>At least one numeric</li>
															<li>At least one special character</li>
															<li>Minimum 8 characters</li>
														</ul>
													</template>
												</p-password>
												<div v-if="v$.user.password.$error" class="validation-error">
													{{ v$.user.password.$errors[0].$message }}
												</div>
											</div>
											<div class="field">
												<label class="control-label req" for="confirm_password"> Confirm Password: </label>
												<p-password
													id="confirm_password"
													v-model="confirm_password"
													placeholder="Confirm New Password"
													:feedback="false"
													toggle-mask
												/>
												<div v-if="v$.confirm_password.$error" class="validation-error">
													{{ v$.confirm_password.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<div class="controls gap-20">
											<div class="field">
												<label class="control-label req" for="company-name">Company:</label>
												<p-input-text
													id="company-name"
													v-model="account.name"
													class="p-inputtext"
													placeholder="Company Name"
												/>
												<div v-if="v$.account.name.$error" class="validation-error">
													{{ v$.account.name.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">How did you hear about us?</label>
										<div class="controls">
											<div class="field">
												<p-text-area
													v-model="account.signup_data.referred_from"
													placeholder="Tell us how you learned about NextGen Leads or any referrals here"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<div class="controls">
											<div class="field flex justify-content-start align-items-center gap-2">
												<label class="control-label req" for="accept-terms-conditions"
													>By providing a telephone number and submitting this form you are consenting to be contacted
													by SMS text message. Message & data rates may apply. You can reply STOP to opt-out of further
													messaging, reply HELP for more information. I agree to the
													<router-link :to="{ path: '/terms' }">Terms and Conditions</router-link></label
												>
												<p-checkbox id="accept-terms-conditions" v-model="accept_terms" :binary="true" />
											</div>
										</div>
										<div v-if="v$.accept_terms.$error" class="validation-error">
											{{ v$.accept_terms.$errors[0].$message }}
										</div>
									</div>
								</div>
							</form>
						</template>
						<template #footer>
							<div class="flex justify-content-end">
								<p-button
									class="w-full flex justify-content-center font-bold"
									style="font-size: 1.5rem"
									@click="submitSignUpForm()"
									:loading="loading"
									>Join NextGen Leads</p-button
								>
							</div>
							<div class="disclaimer-text">
								{{ disclaimer_text }}
							</div>
						</template>
					</p-card>
				</column>
				<gutter size="40px" />
				<column class="column">
					<div class="benefits">
						<h2>Discover the Benefits of NextGen Leads</h2>
						<div class="benefit-section">
							<div class="content">
								<h3>Free CRM Integration</h3>
								<p>Our team will take care of integrating with your existing CRM for seamless lead delivery.</p>
							</div>
							<img src="/assets/img/illustrations/signup/crm.png" alt="CRM Integration Graphic" />
						</div>
						<div class="benefit-section">
							<div class="content">
								<h3>Easy Returns</h3>
								<p>
									Bad Leads? No problem! We allow you to cancel purchases/return leads that do not meet your standards.
								</p>
							</div>
							<img src="/assets/img/illustrations/signup/return.png" alt="Returns Graphic" />
						</div>
						<div class="benefit-section">
							<div class="content">
								<h3>Better Control</h3>
								<p>
									With a variety of ways to buy leads, we'll help you develop a winning strategy to maximize your
									profits.
								</p>
							</div>
							<img src="/assets/img/illustrations/signup/controls.png" alt="Controls Graphic" />
						</div>
						<div class="benefit-section">
							<div class="content">
								<h3>Better Results</h3>
								<p>
									From fair and transparent auctions to detailed reporting, our platform is custom built to help you
									succeed.
								</p>
							</div>
							<img src="/assets/img/illustrations/signup/results.png" alt="Results Graphic" />
						</div>
						<div class="benefit-section">
							<div class="content">
								<h3>Better Support</h3>
								<p>
									We are dedicated to your success and work tirelessly to help you reach your goals. Call or email any
									time!
								</p>
							</div>
							<img src="/assets/img/illustrations/signup/support.png" alt="Support Graphic" />
						</div>
					</div>
				</column>
			</row>
			<gutter size="40px" />
		</div>

		<div v-if="signup_page" class="offer-tag" @click="show_offer_modal = true">
			<span>Your Exclusive Offer!</span>
			<h2 v-if="signup_page.offer.credit_type === 'flat'">
				{{ currency(signup_page.offer.credit_amount, 0) }} for free*
			</h2>
			<h2 v-else>{{ percentage(signup_page.offer.credit_amount) }} for free*</h2>
		</div>
		<div class="phone-tag" v-if="signup_page && signup_page.content.phone">
			<span> Call to Sign Up Today! </span>
			<h2>
				<a :href="`tel:${signup_page.content.phone}`"> {{ phoneFormat(signup_page.content.phone) }} </a>
			</h2>
		</div>
	</div>
</template>

<script lang="ts">
import { padStart, random } from 'lodash-es';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import pMessage from 'primevue/message';
import pTextArea from 'primevue/textarea';
import STATES from '@/lib/Data/states.json';
import { getSignUpOffer, checkExistingAccountId, checkExistingUserEmail, signUp } from '@GQL';
import log from '@/lib/Log';
import { currency, percentage, phoneFormat } from '@/lib/Filters';
import { passwordStrength } from '@/lib/Utils/passwordStrength';
import debounceAsync from 'debounce-async';
import pDivider from 'primevue/divider';

function emailNotBlacklisted(email) {
	const blacklist = ['yopmail'];
	return !blacklist.some(function (val) {
		return email.includes(val);
	});
}

const vertical_options = [
	{
		label: 'Health Insurance',
		value: 'health_insurance',
	},
	{
		label: 'Medicare',
		value: 'medicare',
	},
];

const product_options = [
	{
		label: 'Exclusive Data Leads',
		value: 'Exclusive Data Leads',
	},
	{
		label: 'Live Transfers',
		value: 'Live Transfers',
	},
	{
		label: 'Calls',
		value: 'Calls',
	},
];

const agent_options = [
	{
		label: 'Just Myself',
		value: '1',
	},
	{
		label: '2-9 Agents',
		value: '2-9',
	},
	{
		label: '9-20 Agents',
		value: '9-20',
	},
	{
		label: '20+ Agents',
		value: '20+',
	},
];

const frequency_options = [
	{
		label: 'Daily',
		value: 'day',
	},
	{
		label: 'Weekly',
		value: 'week',
	},
	{
		label: 'Monthly',
		value: 'month',
	},
];

export default {
	name: 'SignUp',
	setup() {
		return { v$: useVuelidate() };
	},
	components: {
		pDivider,
		pMessage,
		pTextArea,
	},
	inject: ['mq'],
	data() {
		return {
			step: 1,
			account: {
				parent_account_id: null, // pull from query or set to null
				group_ids: [],
				is_parent: false,
				support: {
					account_manager_id: '',
					status: 'new',
					category: '',
				},
				settings: {
					timezone: '',
					notifications: [],
					notifications_bcc: [],
					notification_bcc_opt_in: [],
					pause_pending_campaigns: true,
					limit_auction_attempt_participation: {
						enabled: false,
						limit: 1,
					},
					bulk_returns: {
						enabled: false,
						return_rate: 0,
					},
					data_returns: {
						enabled: false,
					},
					credit_terms: {
						enabled: false,
						limit_enabled: false,
						limit: 0,
					},
					budgets: [],
					usha: {
						agent_id: null,
						enable_validation: false,
					},
				},
				signup_source: {
					query_string: this.$route.fullPath,
					// Will need to add query
					source_id: this.$route.query.source_id || this.$route.query.source_code || '',
					s1: this.$route.query.s1 || null,
					s2: this.$route.query.s2 || null,
					s3: this.$route.query.s3 || null,
					s4: this.$route.query.s4 || null,
					s5: this.$route.query.s5 || null,
					internal_referral: false,
				},
				signup_data: {
					primary_vertical: '',
					products: [],
					agency_size: '',
					sign_up_score: this.signupScore,
					referred_from: '',
					landing_page_id: this.$route.query.landing_page_id || null,
					signup_offer_id: this.$route.query.offer_id || null,
				},
				tags: [],
				internal: false,
				verified: false,
				status: 'active',
				created_at: new Date(),
			},
			user: {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				password: '',
			},
			confirm_password: '',
			vertical_options,
			product_options,
			agent_options,
			state_options: [...STATES],
			accept_terms: false,
			disclaimer_text:
				'By clicking Join NextGen Leads, you agree NextGen Leads, LLC may use automated technology and pre-recorded messages to deliver marketing calls or texts to the number you provided regarding your submission and other offers. Consent is not a condition of purchase.',
			// confirmation_email_sent: false,
			loading: false,
			signup_page: null,
			show_offer_modal: false,
		};
	},
	async mounted() {
		if ('sign_up_path' in this.$route.params) {
			// get the stuff
			await this.loadSignUpOffer(this.$route.params.sign_up_path);
		}
		if (this.signup_page.content.phone) {
			this.$root.appStore.show_contact_on_signup = false;
		}
	},
	computed: {
		mpid() {
			if (this.$root.appStore.mpid && this.$root.appStore.mpid !== '$NG') {
				return this.$root.appStore.mpid;
			} else {
				return 'NGL';
			}
		},
		uniqueEmail() {
			return debounceAsync(async function (email) {
				const email_exists = await checkExistingUserEmail(email);
				return !email_exists;
			}, 500);
		},
		signupScore() {
			let running_score = 0;
			running_score += this.products.length * 10;
			if (this.products.indexOf('Calls') > -1) {
				running_score += 20;
			}

			if (this.products.indexOf('Exclusive Data Leads') > -1) {
				running_score += 10;
			}

			return running_score;
		},
		userTimezone() {
			return Intl.DateTimeFormat().resolvedOptions().timeZone;
		},
	},
	methods: {
		percentage,
		phoneFormat,
		currency,
		goToLogin() {
			this.$router.push({ path: '/login' });
		},
		async loadSignUpOffer(path) {
			this.loading = true;

			try {
				const results = await getSignUpOffer([[`status = 'active'`, `path = '${path}'`]]);

				if (results.length === 1) {
					this.signup_page = results[0];

					if (this.signup_page.source) {
						this.account.signup_source.source_id = this.signup_page.source;
					}

					if (this.signup_page.tags) {
						this.account.tags = this.signup_page.tags || [];
					}
					if (this.signup_page.parent_account_id) {
						this.account.parent_account_id = this.signup_page.parent_account_id;
					}
					if (this.signup_page.offer_id) {
						this.account.signup_data.signup_offer_id = this.signup_page.offer_id;
					}
					if (this.signup_page.id) {
						this.account.signup_data.landing_page_id = this.signup_page.id;
					}

					this.show_offer_modal = true;
				} else {
					// quietly never mention any offer...
				}
			} catch (err) {
				console.warn(err);
			} finally {
				this.loading = false;
			}
		},
		async generateAccountId() {
			const account_id = [
				this.mpid,
				padStart(random(0, 999).toString(), 3, '0'),
				padStart(random(0, 999).toString(), 3, '0'),
			].join('-');

			// Check if the account ID already exists
			const existing = await checkExistingAccountId(account_id);
			if (existing) {
				return await this.generateAccountId();
			}
			return account_id;
		},
		async submitSignUpForm() {
			this.loading = true;
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					let new_user_role = {};
					new_user_role[this.mpid] = 'account_admin';
					const new_account_id = await this.generateAccountId();

					const full_account_name = `${this.account.name} - ${this.user.first_name} ${this.user.last_name}`;

					// Get the IP address
					const ip_result = await fetch('https://api.ipify.org');
					const ip_address = await ip_result.text();

					const new_user_params = {
						account_id: new_account_id,
						title: 'Account Admin',
						role_id: new_user_role,
						mfa_enabled: false,
						status: 'active',
						terms: {
							ip_address,
							timestamp: new Date(),
							version: '20220405.4',
						},
						...this.user,
					};

					const new_account_params = {
						...this.account,
						id: new_account_id,
						mpid: this.mpid,
						name: full_account_name,
						display_name: full_account_name,
						contact: {
							email: this.user.email,
							phone: this.user.phone,
						},
					};
					// set timezone directly
					new_account_params.settings.timezone = this.userTimezone;
					// lowercase emails
					new_user_params.email = new_user_params.email.toLowerCase();
					new_account_params.contact.email = new_account_params.contact.email.toLowerCase();

					const result = await signUp(new_user_params, new_account_params, window.location.origin);
					// this.confirmation_email_sent = true;

					this.$toast.add({
						severity: 'success',
						summary: 'Successfully created new account',
						// detail: 'Please check your email inbox for furt',
						life: 3000,
					});

					await this.$root.sessionStore.login({
						email: this.user.email,
						password: this.user.password,
						mpid: this.mpid,
					});
					this.$router.push({ path: '/campaigns' }); // path doesn't exist in ADMIN view be aware
				} catch (err) {
					log.error('An error occurred running the sign up process', err);
					this.$toast.add({
						severity: 'error',
						summary: 'Error creating new account',
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Form errors',
					detail: 'Please fill out all required fields',
					life: 7000,
				});
				this.step = 1;
				this.loading = false;
			}
		},
	},
	validations() {
		return {
			confirm_password: {
				required: helpers.withMessage('Please confirm the password.', required),
				sameAsPassword: helpers.withMessage('Passwords must match.', sameAs(this.user.password)),
			},
			user: {
				first_name: {
					required: helpers.withMessage('Contact first name required.', required),
					restricted_chars: helpers.withMessage(
						'Some special characters are not allowed for first name.',
						helpers.regex(/^[^*|\\"\[\]<>{}=#;@]*$/)
					),
				},
				last_name: {
					required: helpers.withMessage('Contact last name required.', required),
					restricted_chars: helpers.withMessage(
						'Some special characters are not allowed for last name.',
						helpers.regex(/^[^*|\\"\[\]<>{}=#;@]*$/)
					),
				},
				password: {
					required: helpers.withMessage('A password is required', required),
					passwordStrength: helpers.withMessage('Password is not secure enough.', passwordStrength),
				},
				email: {
					required: helpers.withMessage('An email is required.', required),
					email,
					uniqueEmail: helpers.withMessage(
						'A user with that email address already exists.',
						helpers.withAsync(this.uniqueEmail)
					),
					emailNotBlacklisted: helpers.withMessage(
						'This email domain has been blocked, please sign up with another email.',
						emailNotBlacklisted
					),
				},
				phone: {
					required: helpers.withMessage('Phone number required.', required),
					phone: helpers.withMessage('Please enter a valid phone number.', (value) => {
						const phone_pattern = /^\d{10}$/;
						return phone_pattern.test(value);
					}),
				},
			},
			account: {
				name: {
					required: helpers.withMessage('Company name required.', required),
					restricted_chars: helpers.withMessage(
						'Some special characters are not allowed for company name, please use a different name.',
						helpers.regex(/^[^*|\\"\[\]<>{}=#;@]*$/)
					),
				},
				signup_data: {
					products: {
						required: helpers.withMessage('A lead type is required.', required),
					},
					primary_vertical: {
						required: helpers.withMessage('A vertical is required.', required),
					},
					agency_size: {
						required: helpers.withMessage('Agency size is required.', required),
					},
				},
			},
			accept_terms: {
				required,
				sameAsBoolean: helpers.withMessage('You must read and accept the terms and conditions.', sameAs(true)),
			},
		};
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.p-dialog {
	max-width: 600px !important;
}

.header {
	background-image: url('/assets/img/backgrounds/bg-facets.webp');
	background-size: cover;
	display: flex;
	flex-direction: column;
	padding: 6rem 1rem;
	text-align: center;

	h1 {
		color: var(--white);
		font-size: clamp(2rem, 3vw + 3rem, 4rem);
		margin: 0 auto;
		padding: 0.5rem 1rem;
		text-transform: uppercase;
		width: auto;
	}

	.subtitle {
		background: rgb(0 0 0 / 10%);
		color: var(--white);
		font-size: var(--font-size-base);
		line-height: 1.5;
		margin: auto;
		padding: 0.5rem;
		width: auto;
	}

	.mobile({
        padding: 2.5rem 1rem;
    });
}

.special-offer {
	h1 {
		font-size: 50px;
		color: var(--color-a-light);
	}
}

.content-wrapper {
	margin: 0 auto;
	max-width: 1200px;
	padding: 1rem;
	.mobile({
        padding: 0 1rem;
    });
}

.column {
	min-width: calc(360px - 2rem);
}

.clickable {
	&:hover {
		cursor: pointer;
	}
}

#sign-up-form,
#questions-form {
	h2 {
		color: var(--color-b);
		font-size: var(--font-size-h3);
		font-weight: bold;
	}
}

.divider {
	border-color: var(--gray-05);
	margin: 2rem 0;
}

.benefits {
	margin-top: 1.5rem;

	h2 {
		color: var(--color-b);
		font-size: var(--font-size-h3);
	}

	.benefit-section {
		border-top: 1px solid var(--gray-20);
		display: flex;
		gap: 20px;
		justify-content: space-between;
		padding: 1rem 0;

		h3 {
			font-size: var(--font-size-h4);
			font-weight: bold;
			text-transform: uppercase;
		}

		p {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
		}
	}
	.mobile({
        margin: 0 auto;
    });
}

.disclaimer-text {
	margin-top: 2rem;
	font-size: small;
	color: var(--gray-40);
	line-height: 1.3;
}

.logo {
	margin: 50px 0;
	.logo-large {
		max-width: 300px;
	}
}

.tag-line {
	line-height: 1.5;
	text-align: center;
	margin-bottom: 25px;
	font-size: 21px;
}

.offer-tag {
	z-index: 99;
	width: 250px;
	color: white;
	background-color: var(--color-a);
	text-align: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	position: fixed;
	padding: 10px;
	top: auto;
	bottom: 0%;
	left: 20px;
	transition: all 0.2s;
	box-shadow: 0 0 5px rgba(30, 32, 35, 0.12);

	span {
		font-weight: 400px;
		letter-spacing: 1px;
	}

	h2 {
		margin-top: 10px;
		font-size: 25px;
	}

	&:hover {
		cursor: pointer;
		padding-bottom: 20px;
		box-shadow: 0 0 15px rgba(30, 32, 35, 0.24);
	}
}

.offer-header {
	color: var(--color-a);
	font-size: 60px;
}

.phone-tag {
	z-index: 99;
	width: 350px;
	color: white;
	background-color: var(--color-b);
	text-align: center;
	border-radius: 10px;
	position: fixed;
	padding: 20px;
	top: 110px;
	bottom: auto;
	right: 35px;
	transition: all 0.2s;
	box-shadow: 0 0 10px rgba(30, 32, 35, 0.12);

	a {
		color: white;
	}

	span {
		font-weight: 400;
		letter-spacing: 1px;
		font-size: 25px;
	}

	h2 {
		margin: 10px 0 0 0;
		font-size: 25px;
	}

	&:hover {
		cursor: pointer;
	}
}
</style>
