<template>
	<div class="view-content">
		<ActionModal
			ref="action_modal"
			:account_id="selected_account_id"
			:account_name="selected_account_name"
			:account_support_status="account"
			@refresh="handleActionUpdate"
		/>
		<div class="page-heading">
			<h2>Existing Accounts</h2>
		</div>
		<div>
			<row>
				<column>
					<metric label="Revenue Today" :value="metrics.revenue" is-currency />
				</column>
				<gutter size="20px" />
				<column>
					<metric label="Revenue / Account" :value="metrics.revenue_per_account" is-currency />
				</column>
				<gutter size="20px" />
				<column>
					<metric label="Actions Needed" :value="metrics.actions_needed" />
				</column>
				<gutter size="20px" />
				<column>
					<metric label="Active %" :value="metrics.active_account_percent" is-percentage />
				</column>
			</row>
		</div>
		<gutter size="20px" />

		<div class="card padded">
			<query-form v-model:query="query" :loading="loading" @apply="loadExistingAccounts" @abort="clearQuery">
				<template #display>
					<div class="display-value">
						<div class="label">Verticals:</div>
						<div class="value">
							{{ displaySelected(query.verticals, verticalOptions) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Accounts:</div>
						<div class="value">
							{{ query.all_accounts ? 'All' : 'Funded' }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="loadExistingAccounts(true)"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group collapse">
								<div class="inner">
									<label class="control-label">Verticals:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="form.query.verticals"
												:options="verticalOptions"
												option-label="label"
												option-value="value"
												placeholder="Select Verticals"
												class="source-type-select w-full md:w-20rem"
											>
											</p-multi-select>
										</div>
									</div>
								</div>
							</div>
						</column>
						<column>
							<div class="control-group collapse">
								<div class="inner">
									<label class="control-label">All Accounts:</label>
									<div class="controls">
										<div class="field fit">
											<p-input-switch v-model="form.query.all_accounts" />
										</div>
										<div class="field caption">
											<span class="label">Show All Accounts</span>
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<p-data-table
				ref="dt"
				scrollable
				data-key="account_id"
				:loading="loading"
				v-model:expandedRows="expanded_rows"
				:rows="100"
				:value="existing_accounts"
				:pt="{
					wrapper: {
						class: 'overflow-x-visible',
					},
				}"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="name" header="Account" sortable sortField="account_name">
					<template #body="row">
						<div class="flex column justify-content-between gap-2">
							<div v-if="row.data.account_id">
								<router-link
									:to="`/marketplaces/manage/${row.data.account_id.slice(0, 3)}/accounts/manage/${row.data.account_id}/reports/account-activity`"
								>
									{{ row.data.account_name }}
								</router-link>
								<div class="sub-value">{{ row.data.account_id }}</div>
							</div>
							<div class="clickable" v-tooltip.top="'Action Required'" @click="handleAction(row.data)">
								<icon v-if="row.data.action_needed" type="alert" :style="{ color: 'red' }" size="30px" />
								<icon v-else type="pencil" size="30px" />
							</div>
						</div>
					</template>
				</p-column>

				<p-column field="name" header="Balance" sortable sortField="balance" class="align-center">
					<template #body="row">
						{{ currency(row.data.balance || 0) }}
					</template>
				</p-column>

				<p-column
					field="name"
					header="Alerts"
					:style="{ width: '150px' }"
					class="align-center"
					sortable
					sortField="priority_level"
				>
					<template #body="row">
						<div class="flex alert-holder align-content-center" v-if="showAlerts(row.data)">
							<icon
								v-if="row.data.alerts.has_funded"
								v-tooltip.top="`Account has not funded`"
								type="wallet-bifold"
								:style="{ color: 'red' }"
								size="25px"
							/>
							<icon
								v-if="row.data.alerts.low_balance"
								v-tooltip.top="`Balance is too low`"
								:style="{ color: 'red' }"
								type="credit-card-off-outline"
								size="25px"
							/>
							<icon
								v-if="!row.data.alerts.has_active_campaigns"
								v-tooltip.top="`0 active campaigns`"
								:style="{ color: 'red' }"
								type="sleep"
								size="25px"
							/>
							<icon
								v-if="row.data.alerts.has_purchases"
								v-tooltip.top="`Made a purchase today`"
								type="currency-usd"
								:style="{ color: 'green' }"
								size="25px"
							/>
							<icon
								v-if="!row.data.alerts.has_purchases"
								v-tooltip.top="`No purchases today`"
								type="currency-usd-off"
								:style="{ color: 'gray' }"
								size="25px"
							/>
							<icon
								v-if="row.data.alerts.hit_budget"
								v-tooltip.top="`Budget or Throttle was reached`"
								type="car-speed-limiter"
								:style="{ color: 'red' }"
								size="25px"
							/>
						</div>
					</template>
				</p-column>

				<p-column field="verticals" header="Verticals" class="align-center">
					<template #body="row">
						<div class="flex gap-2 justify-content-center align-items-center">
							<vertical-icon v-if="row.data.health_campaign_count > 0" vertical-id="health_insurance" size="30px" />
							<vertical-icon v-if="row.data.medicare_campaign_count > 0" vertical-id="medicare" size="30px" />
						</div>
					</template>
				</p-column>

				<p-column field="disposition" header="Disposition" class="align-center">
					<template #body="row">
						{{ row.data.disposition }}
					</template>
				</p-column>

				<p-column field="name" header="Last Activity" sortable sortField="last_activity" class="align-center">
					<template #body="row">
						{{ row.data.last_activity ? formatDate(new Date(row.data.last_activity), 'M/D/YYYY') : 'None' }}
						<div v-if="row.data.last_activity" class="sub-value">
							{{ timeAgo(row.data.last_activity) }}
						</div>
					</template>
				</p-column>

				<p-column field="name" header="Last Purchase" sortable sortField="last_purchase" class="align-center">
					<template #body="row">
						{{ row.data.last_purchase ? formatDate(new Date(row.data.last_purchase), 'M/D/YYYY') : 'None' }}
						<div v-if="row.data.last_purchase" class="sub-value">
							{{ timeAgo(row.data.last_purchase) }}
						</div>
					</template>
				</p-column>

				<p-column
					field="support_status"
					header="Support Status"
					sortable
					sortField="support_status"
					class="align-center"
				>
					<template #body="row">
						{{ title(row.data.support_status) }}
					</template>
				</p-column>

				<p-column
					field="last_month_spend"
					header="Last Month Spend"
					sortable
					sortField="last_month_spend"
					class="align-center"
				>
					<template #body="row">
						{{ currency(row.data.last_month_spend) }}
					</template>
				</p-column>

				<p-column
					field="last_week_spend"
					header="Last Week Spend"
					sortable
					sortField="last_week_spend"
					class="align-center"
				>
					<template #body="row">
						{{ currency(row.data.last_week_spend) }}
					</template>
				</p-column>

				<p-column
					field="yesterday_spend"
					header="Yesterday Spend"
					sortable
					sortField="yesterday_spend"
					class="align-center"
				>
					<template #body="row">
						{{ currency(row.data.yesterday_spend) }}
					</template>
				</p-column>

				<p-column field="today_spend" header="Spend Today" sortable sortField="today_spend" class="align-center">
					<template #body="row">
						<div class="flex align-items-center justify-content-center gap-2">
							{{ currency(row.data.today_spend) }}
							<Trend
								v-tooltip.top="'Today vs Yesterday'"
								:value="row.data.today_spend"
								:previous-value="row.data.yesterday_spend"
							/>
						</div>
					</template>
				</p-column>

				<p-column field="alltime_spend" header="All Time Spend" sortable sortField="alltime_spend" class="align-center">
					<template #body="row">
						{{ currency(row.data.alltime_spend) }}
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
		</div>
	</div>
</template>

<script>
import { reportExistingAccountsForAccountManager } from '@GQL';
import { displaySelected, formatDate, currency, timeAgo, title } from '@/lib/Filters';
import QueryForm from '@/components/widgets/QueryForm.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import ActionModal from './ActionModal.vue';
import pDataView from 'primevue/dataview';
import pRow from 'primevue/row';
import { sumBy } from 'lodash-es';

import Trend from '@/components/elements/Trend.vue';

export default {
	name: 'ExistingAccountsPage',
	components: {
		pDataView,
		pRow,
		Trend,
		QueryForm,
		verticalIcon,
		ActionModal,
	},
	data() {
		return {
			selected_account_id: '',
			selected_account_name: '',
			selected_account_support_status: '',
			total_records: 0,
			metrics: {
				revenue: 0,
				actions_needed: 0,
				revenue_per_account: 0,
				active_account_percent: 0,
			},
			existing_accounts: [],
			expanded_rows: {},
			query: {
				verticals: [],
				pagination: {
					page_size: 100,
					page: 0,
				},
				all_accounts: false,
			},
			loading: false,
			totals: {},
		};
	},
	computed: {
		accountManagerId() {
			let manager_id = this.$root.sessionStore.user.id;
			if ('account_manager_id' in this.$route.params) {
				manager_id = this.$route.params.account_manager_id;
			}
			return manager_id;
		},
		verticalOptions() {
			return this.$root.appStore.verticalOptions;
		},
	},
	async mounted() {
		await this.loadExistingAccounts();
	},
	methods: {
		title,
		timeAgo,
		formatDate,
		currency,
		displaySelected,
		expandAll() {
			this.expanded_rows = this.existing_accounts.reduce((acc, p) => (acc[p.account_id] = true) && acc, {});
		},
		collapseAll() {
			this.expanded_rows = null;
		},
		showAlerts(row) {
			// dont display alerts for snoozed accounts
			if (
				row.disposition === 'Snoozing Alerts' &&
				row.snooze_until !== null &&
				row.snooze_until > new Date().toISOString()
			) {
				return false;
			} else {
				return true;
			}
		},

		async handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.loadExistingAccounts();
		},
		clearQuery() {
			this.loading = false;
		},
		async loadExistingAccounts(refresh) {
			try {
				this.loading = true;
				const params = {
					...this.query,
					account_manager_id: this.accountManagerId,
				};
				if (refresh) {
					params.refresh = true;
				}
				const res = await reportExistingAccountsForAccountManager(params);
				this.existing_accounts = res.rows.map((row) => {
					row.spend = [
						{
							last_month_spend: row.last_month_spend || 0,
							last_week_spend: row.last_week_spend || 0,
							yesterday_spend: row.yesterday_spend || 0,
							today_spend: row.today_spend || 0,
							alltime_spend: row.alltime_spend || 0,
						},
					];
					return row;
				});
				this.total_records = res.row_count;
				this.metrics = res.metrics;
				this.totals = {
					balance: sumBy(this.existing_accounts, 'balance'),
					last_month_spend: sumBy(this.existing_accounts, 'last_month_spend'),
					last_week_spend: sumBy(this.existing_accounts, 'last_week_spend'),
					yesterday_spend: sumBy(this.existing_accounts, 'yesterday_spend'),
					today_spend: sumBy(this.existing_accounts, 'today_spend'),
					alltime_spend: sumBy(this.existing_accounts, 'alltime_spend'),
				};
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get existing accounts',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		handleAction(row) {
			this.selected_account_name = row.account_name;
			this.selected_account_id = row.account_id;
			this.selected_account_support_status = row.support_status;
			this.$refs.action_modal.openModal();
			// open the modal
		},
		async handleActionUpdate(data) {
			const account_to_update = this.existing_accounts.find((account) => {
				return account.account_id === data.account_id;
			});
			account_to_update.disposition = data.disposition;
			account_to_update.priority_level = 0;
		},
	},
};
</script>
<style scoped lang="less">
.alert-holder {
	justify-content: space-between;
}
.clickable:hover {
	cursor: pointer;
}
</style>
