<template>
	<div class="date-range">
		<div class="date-picker">
			<label class="control-label">Date From:</label>
			<div>
				<p-date-picker label="" v-model="startDate" show-time :show-icon="false" />
			</div>
		</div>
		<div class="gutter" />
		<div class="date-picker">
			<label class="control-label">Date To:</label>
			<div>
				<p-date-picker label="" v-model="endDate" show-time :show-icon="false" />
			</div>
		</div>
		<div class="gutter" />
		<div class="menu-container">
			<p-button icon="pi pi-calendar" @click="toggleMenu" />
			<p-menu ref="menu" class="date-range-menu" :model="menu_items" :popup="true" />
		</div>
	</div>
</template>

<script lang="ts">
import { padStart } from 'lodash-es';
import pButton from 'primevue/button';
import pCalendar from 'primevue/calendar';
import pDatePicker from './DatePicker.vue';
import pMenu from 'primevue/menu';
import pText from 'primevue/inputtext';
import {
	add,
	endOfDay,
	endOfMonth,
	endOfWeek,
	isAfter,
	isBefore,
	set,
	startOfDay,
	startOfMonth,
	startOfWeek,
	sub,
} from 'date-fns';

export default {
	name: 'DateRange',
	components: {
		pButton,
		pCalendar,
		pDatePicker,
		pMenu,
		pText,
	},
	props: {
		modelValue: {
			type: Array,
			required: true,
		},
		rangeLimit: {
			type: Object,
			default() {
				return {
					days: 31,
				};
			},
		},
		showTime: Boolean,
	},
	mounted() {
		if (this.rangeLimit.days >= 62) {
			this.menu_items.push({
				label: 'Last 62 Days',
				icon: 'pi pi-calendar',
				command: () => {
					this.setDates('last_62_days', true);
				},
			});
		}
		this.menu_items.push({
			label: 'Reset',
			icon: 'pi pi-refresh',
			command: () => {
				this.setDates('reset');
			},
		});
	},
	data() {
		return {
			default_start: this.modelValue[0],
			default_end: this.modelValue[1],
			end_min: null,
			end_max: null,
			menu_items: [
				{
					label: 'Today',
					icon: 'pi pi-calendar',
					command: () => {
						this.setDates('today');
					},
				},
				{
					label: 'Yesterday',
					icon: 'pi pi-calendar',
					command: () => {
						this.setDates('yesterday');
					},
				},
				{
					label: 'This Week',
					icon: 'pi pi-calendar',
					command: () => {
						this.setDates('this_week');
					},
				},
				{
					label: 'Last Week',
					icon: 'pi pi-calendar',
					command: () => {
						this.setDates('last_week');
					},
				},
				{
					label: 'This Month',
					icon: 'pi pi-calendar',
					command: () => {
						this.setDates('this_month');
					},
				},
				{
					label: 'Last Month',
					icon: 'pi pi-calendar',
					command: () => {
						this.setDates('last_month');
					},
				},
				{
					label: 'Last 31 Days',
					icon: 'pi pi-calendar',
					command: () => {
						this.setDates('last_31_days', true);
					},
				},
			],
			start_time: {
				hours: '00',
				minutes: '00',
				seconds: '00',
			},
			end_time: {
				hours: '23',
				minutes: '59',
				seconds: '59',
			},
		};
	},
	computed: {
		startDate: {
			get() {
				return this.modelValue[0];
			},
			set(new_value) {
				if (this.modelValue[0] !== new_value) {
					let end_date = this.endDate;

					if (isAfter(this.modelValue[1], add(new_value, this.rangeLimit))) {
						end_date = endOfDay(add(new_value, this.rangeLimit));
					}

					if (isBefore(this.modelValue[1], new_value)) {
						end_date = endOfDay(new_value);
					}

					this.$emit('update:modelValue', [new_value, end_date]);
				}
			},
		},
		endDate: {
			get() {
				return this.modelValue[1];
			},
			set(new_value) {
				if (this.modelValue[1] !== new_value) {
					let start_date = this.startDate;

					if (isBefore(this.modelValue[0], sub(new_value, this.rangeLimit))) {
						start_date = startOfDay(sub(new_value, this.rangeLimit));
					}

					if (isAfter(this.modelValue[0], new_value)) {
						start_date = startOfDay(new_value);
					}

					this.$emit('update:modelValue', [start_date, new_value]);
				}
			},
		},
	},
	methods: {
		toggleMenu(event) {
			this.$refs.menu.toggle(event);
		},
		setDates(when, velocity = false) {
			let start, end;
			let now = new Date();
			switch (when) {
				case 'today':
					start = startOfDay(now);
					end = endOfDay(now);
					break;
				case 'yesterday':
					start = startOfDay(sub(now, { days: 1 }));
					end = endOfDay(sub(now, { days: 1 }));
					break;
				case 'this_week':
					start = startOfWeek(now, { weekStartsOn: 1 });
					end = endOfWeek(now, { weekStartsOn: 1 });
					break;
				case 'last_week':
					start = startOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 });
					end = endOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 });
					break;
				case 'this_month':
					start = startOfMonth(now);
					end = endOfMonth(now);
					break;
				case 'last_month':
					start = startOfMonth(sub(now, { months: 1 }));
					end = endOfMonth(sub(now, { months: 1 }));
					break;
				case 'last_31_days':
					start = startOfDay(sub(now, { days: 31 }));
					end = endOfDay(now);
					break;
				case 'last_62_days':
					start = startOfDay(sub(now, { days: 62 }));
					end = endOfDay(now);
					break;
				case 'reset':
					this.$emit('update:modelValue', [this.default_start, this.default_end]);
					break;
			}

			if (when !== 'reset') {
				// Set the end time to now for velocity
				if (velocity) {
					end = set(end, {
						hours: now.getHours(),
						minutes: now.getMinutes(),
						seconds: now.getSeconds(),
					});
				}

				// Emit the updates dates
				this.$emit('update:modelValue', [start, end]);
			}
		},
		setLimit() {
			this.end_min = this.startDate;
			this.end_max = add(this.startDate, this.rangeLimit);
		},
		inRange(date_obj, reference) {
			const the_date = set(reference, {
				date: date_obj.day,
				month: date_obj.month,
				year: date_obj.year,
			});
			return isAfter(the_date, this.modelValue[0]) && isBefore(the_date, this.modelValue[1]);
		},
		updateTimeValue(event, value, segment, max = 59) {
			let number_pattern = new RegExp('[0-9]|Tab|Backspace|ArrowLeft|ArrowRight|Delete');
			if (!number_pattern.test(event.key) && !event.metaKey && !event.ctrlKey) {
				event.preventDefault();
			}

			let new_value = parseInt(value[segment]);
			if (event.key === 'ArrowUp') {
				if (event.shiftKey) {
					new_value += 10;
				} else {
					new_value++;
				}
				if (new_value > max) new_value = 0;
				value[segment] = padStart(new_value.toString(), 2, '0');
			}
			if (event.key === 'ArrowDown') {
				if (event.shiftKey) {
					new_value -= 10;
				} else {
					new_value--;
				}
				if (new_value < 0) new_value = max;
				value[segment] = padStart(new_value.toString(), 2, '0');
			}
		},
		limitTimeLength(event, value, segment) {
			if (event.target.value.length > 2) {
				value[segment] = event.target.value.slice(-2);
			}
		},
		sanitizeValue(event, value, segment, max = 59) {
			if (value[segment] > max) {
				value[segment] = max;
			}
		},
		setTimeTo(value, when) {
			if (when === 'start') {
				value.hours = '00';
				value.minutes = '00';
				value.seconds = '00';
			}
			if (when === 'now') {
				let now = new Date();
				value.hours = padStart(now.getHours().toString(), 2, '0');
				value.minutes = padStart(now.getMinutes().toString(), 2, '0');
				value.seconds = padStart(now.getSeconds().toString(), 2, '0');
			}
			if (when === 'end') {
				value.hours = '23';
				value.minutes = '59';
				value.seconds = '59';
			}
		},
		updateTimeValues() {
			if (this.showTime) {
				if (Array.isArray(this.modelValue)) {
					const start = set(this.modelValue[0], {
						hours: this.start_time.hours,
						minutes: this.start_time.minutes,
						seconds: this.start_time.seconds,
					});
					let end = null;
					if (this.modelValue[1]) {
						end = set(this.modelValue[1], {
							hours: this.end_time.hours,
							minutes: this.end_time.minutes,
							seconds: this.end_time.seconds,
						});
					}
					this.$emit('update:modelValue', [start, end]);
				} else {
					const start = set(this.modelValue, {
						hours: this.start_time.hours,
						minutes: this.start_time.minutes,
						seconds: this.start_time.seconds,
					});
					this.$emit('update:modelValue', start);
				}
			}
		},
	},
};
</script>

<style scoped lang="less">
.date-range {
	align-items: flex-end;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	margin-bottom: -2px;

	.menu-container {
		padding-bottom: 2px;
	}
}

.date-picker {
	width: 100%;

	label,
	.p-calendar {
		width: 100%;
	}
}

.gutter {
	flex: 0 0 10px;
}

:global(li.p-menuitem) {
	font-size: 0.875rem;
}
</style>

<style lang="less">
@import (reference) '@/styles/themes/default';

.p-datepicker {
	> .p-timepicker {
		display: none;
	}

	.p-datepicker-footer {
		border-top: 1px solid var(--gray-10);
		padding: 20px;
		text-align: center;
	}

	.timepicker {
		align-items: center;
		display: flex;
		justify-content: center;

		> .time-segment {
			max-width: 50px;

			.p-inputtext {
				max-width: 100%;
				text-align: center;
			}
		}

		> .separator {
			text-align: center;
			width: 10px;
		}
	}

	.p-datepicker-calendar td {
		.in-range {
			align-items: center;
			background-color: var(--gray-10);
			display: flex;
			height: 100%;
			justify-content: center;
			width: 100%;
		}
	}
}
</style>
