<template>
	<div class="account-spend-by-day-chart">
		<row>
			<div class="column">
				<div class="card">
					<div class="card-heading padded justify-content-between flex-wrap">
						<div class="flex align-items-center gap-2 justify-content-start pt-3 sm:pt-0">
							<icon type="chart-line" size="24px" />
							<strong>Spend (Last 7 Days)</strong>
						</div>
						<div class="flex align-items-center justify-content-end spend-filter flex-wrap">
							<quick-filter v-model="selected_filter" value="total">Total</quick-filter>
							<quick-filter v-model="selected_filter" value="data">Data</quick-filter>
							<quick-filter v-model="selected_filter" value="call">Calls</quick-filter>
							<quick-filter v-model="selected_filter" value="live_transfer">Live Transfers</quick-filter>
							<quick-filter v-model="selected_filter" value="ad">Ads</quick-filter>
						</div>
					</div>
					<div class="padded">
						<gutter size="20px" />
						<line-chart :chart-data="chartData" :custom-options="chart_options" />
					</div>
				</div>
			</div>
		</row>
	</div>
</template>

<script lang="ts">
import lineChart from '@/components/charts/Line.vue';
import dayjs from 'dayjs';
import { reportAccountSpendByDay } from '@GQL';
import { currency } from '@/lib/Filters';
import { uniq, groupBy, map } from 'lodash-es';
import quickFilter from '@/components/widgets/QuickFilter.vue';

export default {
	name: 'AccountSpendByDayChart',
	components: {
		lineChart,
		quickFilter,
	},
	data() {
		return {
			query: {
				date_range: [dayjs().subtract(7, 'day').startOf('day').toDate(), dayjs().endOf('day').toDate()],
			},
			spend_by_day: [],
			selected_filter: 'total',
			chart_options: {
				responsive: true,
				plugins: {
					tooltip: {
						callbacks: {
							label: function (tooltip) {
								if (tooltip.dataset.label.indexOf('Spend') > -1) {
									return `${tooltip.dataset.label}: ${currency(tooltip.raw)}`;
								}
								return `${tooltip.dataset.label}: ${tooltip.raw}`;
							},
						},
					},
				},
				scales: {
					y: {
						type: 'linear',
						display: true,
						position: 'left',
						min: 0,
						ticks: {
							callback: function (value) {
								return currency(value);
							},
						},
					},
				},
				layout: {
					padding: 30,
				},
			},
		};
	},
	methods: {
		currency,
		groupBy,
		sumSpendByProduct(data) {
			const last7Days = Array.from({ length: 7 }, (_, i) => dayjs().subtract(i, 'day').format('YYYY-MM-DD')).reverse();

			// Initialize result array
			let product_spend = [];
			last7Days.forEach((day) => {
				product_spend.push({
					date: day,
					product: {
						total: 0,
						data: 0,
						call: 0,
						live_transfer: 0,
						ad: 0,
					},
				});
			});

			const groupedByDate = groupBy(data, (entry) => entry.created_at_day.split('T')[0]);
			const resultArray = map(groupedByDate, (entries, date) => {
				const product = {};
				entries.forEach((entry) => {
					if (product[entry.product]) {
						product[entry.product] += entry.spend;
					} else {
						product[entry.product] = entry.spend;
					}
				});

				return {
					date,
					product,
				};
			});

			// Merge the two arrays
			const result = product_spend.map((day) => {
				const found = resultArray.find((entry) => entry.date === day.date);
				if (found) {
					return {
						...day,
						product: {
							...day.product,
							...found.product,
						},
					};
				}
				return day;
			});

			return result;
		},
	},
	computed: {
		chartData() {
			let summed_by_day = this.sumSpendByProduct(this.spend_by_day).map((d: any) => d.product[this.selected_filter]);
			const last7Days = Array.from({ length: 7 }, (_, i) => dayjs().subtract(i, 'day').format('YYYY-MM-DD')).reverse();
			return {
				labels: last7Days.map((d: any) => d.slice(5, 10)),
				datasets: [
					{
						label: `Spend (${this.selected_filter})`,
						data: summed_by_day,
						fill: true,
						borderColor: '#27A69A',
						backgroundColor: 'rgba(39, 166, 154, 0.1)',
					},
				],
			};
		},
		accountId() {
			return this.$route.params.account_id;
		},
	},
	async mounted() {
		this.spend_by_day = (await reportAccountSpendByDay({ ...this.query, account_id: this.accountId })).spend;
	},
};
</script>

<style lang="less" scoped>
.spend-filter {
	font-size: 0.75em;
}
</style>
